import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TestDriveState } from './testDrive.state';

export const TEST_DRIVE_STATE_NAME = 'testdrive';
const selectTestDriveState = createFeatureSelector<TestDriveState>(
  TEST_DRIVE_STATE_NAME
);
export const selectTestDriveCount = createSelector(
  selectTestDriveState,
  state => state.total
);
const selectTestDrive = createSelector(
  selectTestDriveState,
  ({ registrations }) => registrations
);
const selectFilter = createSelector(
  selectTestDriveState,
  ({ filter }) => filter
);
const selectPagination = createSelector(
  selectTestDriveState,
  ({ pagination }) => pagination
);

const selectPaginationAndFilter = createSelector(
  selectTestDriveState,
  ({ pagination, filter }) => ({ filter, pagination })
);
const selectTotal = createSelector(selectTestDriveState, ({ total }) => total);
export default {
  selectFilter,
  selectTestDrive,
  selectTotal,
  selectPagination,
  selectPaginationAndFilter,
};
