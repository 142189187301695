import {
  ApiResponse,
  TestDrive,
  Pagination,
  TestDrivePageModel,
} from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const testdriveActions = createActionGroup({
  source: '[TestDrive page]',
  events: {
    'fetch TestDrive': emptyProps(),
    'fetch testdrive success': props<{
      registrations: ApiResponse<TestDrive[]>;
    }>(),
    'handle resolve': props<{
      registrations: { ids: TestDrive['id'][] };
    }>(),
    'update filter': props<{ data: TestDrivePageModel.filter }>(),
    'update Pagination': props<{ pagination: Pagination }>(),
    'handle resolve success': props<{
      ids: TestDrive['id'][];
    }>(),
    'export to csv': emptyProps(),
  },
});
export default testdriveActions;
